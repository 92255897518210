import React from 'react';
import styles from '../../styles/layout.module.css';

import { Layout } from 'antd';

const Footer = () => {
  return <Layout.Footer className={styles.footer}>©2022 Team Hopae Inc. All Rights Reserved</Layout.Footer>;
};

export default Footer;
